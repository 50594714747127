function initOwl(a, o){
	if($(a).children().length > 1){
		$(a).owlCarousel(o);
	}else{
		$(a).addClass('.owl-loaded');
	}
}

initOwl('#produtosIndex',{
	responsive: {
		992: {
			items: 4
		},
		600: {
			items: 2
		},
		0:{
			items: 1
		}
	},
	loop: true,
	nav: true,
	navText:[
		'<i class="fa fa-chevron-left"></i>',
		'<i class="fa fa-chevron-right"></i>'
	]
});