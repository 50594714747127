$('.nav-bar').affix({
	offset: {
		top: function(){
			return (this.top = $('.topo').innerHeight())
		}
	}
});

$('.nav-bar').on('affix.bs.affix', function(){
	$('.topo').css('margin-bottom', $(this).outerHeight());
});

$('.nav-bar').on('affix-top.bs.affix', function(){
	$('.topo').css('margin-bottom', '0');
});