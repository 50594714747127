$('#slider-range').slider({
  range: true,
  min: parseInt($('#slider-range').data('min')),
  max: parseInt($('#slider-range').data('max')),
  values: [
    parseInt($('#slider-range').data('min')), parseInt($('#slider-range').data('max'))
  ],
  slide: function(event, ui){
    var output = $(this).data('output');

    var min = ui.values[ 0 ];
    var max = ui.values[ 1 ];

    $(output).val( "R$" + ui.values[ 0 ] + ",00 até R$" + ui.values[ 1 ]+',00' );
    console.log(output)

    $('#text-output .min').text(min);
    $('#text-output .max').text(max);
  }
});